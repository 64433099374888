import { useEffect, useState, useMemo, useCallback, useContext } from "react";
import { AppConfigContext } from "../context";
import BackendApiService from "../service/BackendApiService";
import { useOktaAuth } from "@okta/okta-react";

const useUserInfo = () => {
  const { authState } = useOktaAuth();

  const [userInfo, setUserInfo] = useState(null);
  const [userLoading, setLoading] = useState(true);
  const [userError, setError] = useState(null);

  const appConfig = useContext(AppConfigContext);

  const service = useMemo(
    () => new BackendApiService(appConfig.app.backendApiUrl, authState),
    [appConfig.app.backendApiUrl, authState]
  );

  const fetchUserInfo = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await service.fetchUserInfo();
      const userInfos = await response.json();
      setUserInfo(userInfos);
    } catch (error) {
      console.error("Failed to fetch user info:", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [service]);

  useEffect(() => {
    if(!userInfo) {
      fetchUserInfo();
    }
  }, [fetchUserInfo, userInfo]);

  return { userInfo, userLoading, userError };
};

export default useUserInfo;
