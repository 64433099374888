import { useContext, useEffect, useMemo, useState, useCallback } from "react";
import {
    Button,
    Checkbox,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    TextField,
    CircularProgress,
    Typography,
    IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import { useOktaAuth } from "@okta/okta-react";
import { AppConfigContext } from "../context";
import BackendApiService from "../service/BackendApiService";
import debounce from "lodash.debounce";

export default function RemplacerManagerDialog(props) {
    const { onClose, open, collabsEtablissement, source } = props;

    const { authState } = useOktaAuth();

    const [loading, setLoading] = useState(true);
    const [individus, setIndividus] = useState([]);
    const [newManager, setNewManager] = useState(null);
    const [rows, setRows] = useState(collabsEtablissement);
    const [searched, setSearched] = useState("");
    const [selectedCheckbox, setSelectedCheckbox] = useState(true);
    const [error, setError] = useState(null);

    const appConfig = useContext(AppConfigContext);

    const service = useMemo(
        () => new BackendApiService(appConfig.app.backendApiUrl, authState),
        [appConfig.app.backendApiUrl, authState]
    );

    useEffect(() => {
        if (selectedCheckbox) {
            setRows(collabsEtablissement);
        } else {
            setRows(individus);
        }
        setLoading(false);
    }, [selectedCheckbox, collabsEtablissement, individus]);

    const fetchIndividus = useCallback(() => {
        const params = {
            isActive: true,
            searchedValue: searched,
            maxSize: 50,
        };
        setLoading(true);
        setError(null);
        service
            .fetchIndividus(params)
            .then(async (response) => {
                if (response.status === 400) {
                    setError(await response.text());
                } else if (response.status === 204) {
                    setIndividus([]);
                } else {
                    setIndividus(await response.json());
                }
            })
            .catch((error) => {
                console.error("Failed to fetch individus:", error);
                setError(error);
            })
            .finally(() => setLoading(false));
    }, [searched, service]);

    const debouncedFetchIndividus = useMemo(
        () => debounce(fetchIndividus, 500),
        [fetchIndividus]
    );

    useEffect(() => {
        if (!selectedCheckbox && searched !== "") {
            debouncedFetchIndividus();
        }
        return () => debouncedFetchIndividus.cancel();
    }, [debouncedFetchIndividus, searched, selectedCheckbox]);

    const onInputChange = (event) => {
        const searchedVal = event?.target.value;
        setSearched(searchedVal);
        if (selectedCheckbox) {
            const filteredCollabs = collabsEtablissement.filter((collab) => {
                return (
                    collab.nom
                        .toLowerCase()
                        .includes(searchedVal.toLowerCase()) ||
                    collab.prenom
                        .toLowerCase()
                        .includes(searchedVal.toLowerCase())
                );
            });
            setRows(filteredCollabs);
        }
    };

    const handleSelectCheckbox = () => {
        setSelectedCheckbox(!selectedCheckbox);
        setSearched("");
    };

    const handleCloseConfirm = () => {
        onClose(newManager);
    };

    const handleCloseCancel = (event, reason) => {
        if (reason && reason === "backdropClick") return;
        onClose();
    };
    return (
        <Dialog
            open={open}
            maxWidth='sm'
            sx={{
                "& .MuiDialog-paper": {
                    width: "600px",
                    maxWidth: "50%",
                    maxHeight: "800px",
                },
            }}
        >
            <DialogTitle
                sx={{
                    position: "sticky",
                    top: 0,
                    backgroundColor: "white",
                    zIndex: 1,
                    borderBottom: "1px solid #ddd",
                }}
            >
                <FormControl
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        alignItems: "center",
                        justifyContent: "space-between", // Centers children vertically (if needed)
                    }}
                >
                    <FormLabel
                        id='radio-buttons-group'
                        sx={{
                            textAlign: "center",
                            fontSize: "18px",
                            fontWeight: "bold",
                        }}
                    >
                        Sélectionner le nouveau Manager {source}
                    </FormLabel>
                    <IconButton
                        aria-label='close'
                        onClick={handleCloseCancel}
                        sx={{
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </FormControl>
                <FormControl>
                    {source === "ADB" && (
                        <>
                            <FormControlLabel
                                sx={{
                                    marginBottom: "16px",
                                    marginTop: "16px",
                                }}
                                label={"Collaborateurs de l’agence"}
                                control={
                                    <Checkbox
                                        checked={selectedCheckbox}
                                        onChange={handleSelectCheckbox}
                                        disabled={loading}
                                    />
                                }
                            />
                        </>
                    )}
                    {/* <Divider sx={{ mb: 2 }}></Divider> */}
                    <TextField
                        id='standard-basic'
                        type='search'
                        placeholder='NOM Prénom'
                        onChange={onInputChange}
                        value={searched}
                        variant='standard'
                        InputProps={{
                            startAdornment: (
                                <InputAdornment>
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
            </DialogTitle>
            <FormControl sx={{ marginLeft: "10px", marginTop: "10px" }}>
                {loading && (
                    <Typography
                        sx={{ fontSize: 14 }}
                        color='text.secondary'
                        gutterBottom
                    >
                        Chargement... <CircularProgress size={20} />
                    </Typography>
                )}

                {!loading &&
                    (error ? (
                        <Typography
                            sx={{ fontSize: 14 }}
                            color='text.secondary'
                            gutterBottom
                        >
                            {error}
                        </Typography>
                    ) : (
                        <>
                            <DialogContent>
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby='radio-buttons-group'
                                        name='managergl-selection'
                                        onChange={(e) =>
                                            setNewManager(JSON.parse(e?.target?.value))
                                        }
                                    >
                                        {rows.map((collab, key) => (
                                            <FormControlLabel
                                                value={JSON.stringify(collab)}
                                                key={key}
                                                control={<Radio />}
                                                label={
                                                    collab.nom +
                                                    " " +
                                                    collab.prenom
                                                }
                                            />
                                        ))}
                                    </RadioGroup>
                                </FormControl>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseCancel}>
                                    Annuler
                                </Button>
                                {newManager?.idIndividu && (
                                    <Button onClick={handleCloseConfirm}>
                                        Confirmer
                                    </Button>
                                )}
                            </DialogActions>
                        </>
                    ))}
            </FormControl>
        </Dialog>
    );
}
